import React from "react"
import Footer from '../components/footer'
import SEO from "../components/seo"
import MainBanner from "../components/main-banner"


const IndexPage = () => (
	<>
    <SEO title='Home' />
    <div className='wrapper'>
      <MainBanner/>
      {/* <Footer /> */}
	  </div>
	</>
)

export default IndexPage