import React, { Component } from 'react'

class Footer extends Component {

  render() {
    return (
      <>
        <section className='footer'>
          <div className='footer__inner'>

          </div>
        </section>
      </>
    )
  }
}

export default Footer