import React, { Component } from 'react'
import Background from '../assets/images/background.jpg'
import Logo from '../assets/images/approach-logo.svg'

class MainBanner extends Component {

  render() {

    return(
      <>
        <section className="main-banner">
          <div className="main-banner__inner">
            <img className="background" src={Background} />
            <div className="overlay" />
            <div className="content">
              <img className="logo" src={Logo}/> 
              <div className="text">
                <p>Under Construction </p>
                <p>|</p>
                <p>Contact us<a href="mailto:phil@approachgrp.com.au">here</a></p>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default MainBanner

